/*!
 * 2022 netzkolchose.de
 * GPL Version 3
 * Author: Stephan S. Hepper
 */

import "scss/index.scss";
import 'swiper/css/bundle'; // swiper bundle just globally

import { plugin_registry } from 'nk-plugin-registry';

// import your plugins here.
// site wides
import "components/page_header";
import "components/inpage_nav";

// cms_plugins
import "image/js/image";
import "image_gallery/js/image_gallery";
import "map/js/map";
import "mega_header/js/mega_header";
import "page_footer/js/page_footer";
import "project_stage/js/project_stage";
import "section/js/section";
import "sponsors/js/sponsors";
import "stage/js/stage";
import "standard_header/js/standard_header";
import "story_line/js/story_line";
import "video_player/js/video_player";


plugin_registry.init();
