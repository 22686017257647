import "../scss/image_gallery.scss";
import { WEBP_SUPPORTED } from "helpers/webp"
import { plugin_registry, PluginBase } from "nk-plugin-registry";

import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";

@plugin_registry.register("ImageGallery")
class ImageGallery extends PluginBase {
  constructor($node) {
    super($node);

  }

  disconnect($node) {
    super.disconnect($node);
    this._viewer?.destroy();
    this._viewer = null;
  }

  show_gallery = (index) => (event) => {
    this._viewer.view(index);
  }

  loaded($node){
    super.loaded($node);
    this._gallery_triggers = $node.querySelectorAll('[data-js-select="gallery-trigger"]');

    this._gallery_triggers.forEach(($trigger, index) => $trigger.addEventListener('click', this.show_gallery(index)));

    this._gallery_root = $node.querySelector('[data-js-select="gallery"]');
    this._viewer = new Viewer(
      this._gallery_root,
      {
        className: 'gallery-viewer',
        toolbar: {
          zoomIn: false,
          zoomOut: false,
          oneToOne: false,
          reset: false,
          prev: true,
          play: {
            show: false,
            size: 'large',
          },
          next: true,
          rotateLeft: false,
          rotateRight: false,
          flipHorizontal: false,
          flipVertical: false,
        },
        url: (image) => {
          return WEBP_SUPPORTED?image.dataset['originalUrlWebp']:image.dataset['originalUrl']
        },
      }
    );
  }
}
