import {plugin_registry, PluginBase} from "nk-plugin-registry";

import "../scss/video_player.scss";

@plugin_registry.register('VideoPlayer')
class VideoPlayer extends PluginBase {
  constructor($node) {
    super($node);
    this._$node = $node;
  }

  connect($node) {
    super.connect($node);
    this._$node = $node;
  }

  loaded($node) {
    super.loaded($node);
    this._$video_overlay = this._$node.querySelector('[data-js-select="play-overlay"]');
    this._$video_overlay_activate_button = this._$node.querySelector('[data-js-select="activate-button"]');
    this._$video = this._$node.querySelector('[data-js-select="youtube-embed"]');

    this._$video_overlay_activate_button?.addEventListener('click', this.activate)
  }

  disconnect($node) {
    super.disconnect($node);
    this._$node = null;
    this._$video_overlay_activate_button = null;
    this._$video_overlay = null;
  }

  activate = () => {
    this._$video_overlay.classList.add('hide');
    this._$video.classList.remove('hide');
    const video_url = this._$video.dataset['src'];
    this._$video.setAttribute('src', video_url);
    this._$video_overlay_activate_button?.removeEventListener('click', this._activate);
    this._$video_overlay_activate_button = null;
  }
}
