import {plugin_registry, PluginBase} from "nk-plugin-registry"


@plugin_registry.register('InPageNav')
class InPageNav extends PluginBase {
  constructor($node) {
    super($node);
    this.$node = $node;
    this._$template = null;
    this._$entries = null;
  }

  loaded($node) {
    super.loaded($node);
    this._$template = $node.querySelector('template[data-js-select="inpage-nav-entry"]');
    this._$entries = $node.querySelector('[data-js-select="inpage-nav-entries"]');

    this.update();
  }

  get header_height() {
    return document.body.querySelector('.page-header').getBoundingClientRect().height
  }

  go_to = ($target) => (event) => {
    event.preventDefault();
    window.scrollTo({
      top: $target.getBoundingClientRect().top - this.header_height,
      behavior: 'smooth',
    });
  }

  update = () => {
    if (this._$template && this._$entries) {
      this._$entries.innerHTML = ''

      document.querySelectorAll('[data-inpage-nav-title]').forEach(
        ($target) => {
          const title = $target.dataset.inpageNavTitle;
          const anchor = $target.getAttribute('id');
          const nodes = this._$template.cloneNode(true);
          const $link = nodes.content.querySelector('[data-js-select="inpage-nav-entry-link"]');
          $link.setAttribute('href', `#${anchor}`);
          $link.innerHTML = title;
          $link.addEventListener('click', this.go_to($target));
          this._$entries.appendChild(nodes.content);
        }
      );
    }
  }
}
