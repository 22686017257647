import Swiper, {Navigation, Pagination, Autoplay} from "swiper";

import "../scss/stage.scss";
import {plugin_registry, PluginBase} from "nk-plugin-registry";

@plugin_registry.register("Stage")
class Stage extends PluginBase {
  constructor($node) {
    super($node);
    this._$node = $node;
  }

  loaded($node) {
    super.loaded($node);
    this._swiper = new Swiper(
      this._$node.querySelector('[data-js-select="swiper"]'),

      {
        modules: [Navigation, Pagination, Autoplay],
        autoHeight: true,
        autoplay: {
          delay: 5000,
          pauseOnMouseEnter: true,
        },
        navigation: {
          nextEl: this._$node.querySelector('[data-js-select="swiper-next"]'),
          prevEl: this._$node.querySelector('[data-js-select="swiper-prev"]'),
        },
        pagination: {
          el: this._$node.querySelector('[data-js-select="swiper-pagination"]'),
        },
        loop: true,
      },
    );
  }

  disconnect($node) {
    super.disconnect($node);
    this._swiper.disable();
    this._swiper = null;
    this._$node = null;
  }
}
