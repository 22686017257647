import Swiper, {EffectFade, Autoplay} from "swiper";

import "../scss/project_stage.scss";
import {plugin_registry, PluginBase} from "nk-plugin-registry";

@plugin_registry.register("ProjectStage")
class Stage extends PluginBase {
  constructor($node) {
    super($node);
    this._$node = $node;
  }

  loaded($node) {
    super.loaded($node);
    const $slider = this._$node.querySelector('[data-js-select="swiper"]');
    const num_slides = $slider.querySelector('.slide').length;
    this._swiper = new Swiper(
      $slider,
      {
        modules: [EffectFade, Autoplay ],
        autoHeight: true,
        autoplay: num_slides > 1
          ?{
              delay: 5000,
          }
          :false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        speed: 2000,
        navigation: {
          nextEl: this._$node.querySelector('[data-js-select="swiper-next"]'),
          prevEl: this._$node.querySelector('[data-js-select="swiper-prev"]'),
        },
        pagination: {
          el: this._$node.querySelector('[data-js-select="swiper-pagination"]'),
        },

        loop: true,
      },
    );
  }

  disconnect($node) {
    super.disconnect($node);
    this._swiper.disable();
    this._swiper = null;
    this._$node = null;
  }
}
