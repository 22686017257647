import "../scss/standard_header.scss";
import {plugin_registry, PluginBase} from "../../../main/js/nk-plugin-registry";


@plugin_registry.register("StandardHeader")
class StandardHeader extends PluginBase {
    loaded($node) {
        super.loaded($node);
    }
}
