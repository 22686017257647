import "../scss/map.scss";

import {plugin_registry, PluginBase} from "nk-plugin-registry";

import L from "leaflet";
import 'leaflet/dist/leaflet.css';

import MAP_PIN_ICON from 'map/assets/map_pin.svg';
import MAP_PIN_INTERACTIVE_ICON from 'map/assets/map_pin_interactive.svg';



@plugin_registry.register('Map')
class StationMap extends PluginBase {
  constructor($node) {
    super($node);
    this.$node = $node;

  }

  loaded($node) {
    super.loaded($node);

    this._$map = $node.querySelector('[data-js-select="map"]');
    this._config = JSON.parse($node.querySelector('[data-js-select="config"]').innerText);
    this._center = this._convert_station_location(this._config.center);
    this._markers = [];

    this._map = L.map(this._$map, {
      attributionControl: false,
      fullscreenControl: false,
      touchZoom: false,
      scrollWheelZoom: false,
      zoomControl: false,
      minZoom: 6,
      maxZoom: 20,
    }).setView(this._center, this._config.zoom)

    L.control.zoom({
      position: 'topright'
    }).addTo(this._map);

    this._tile_layer = L.tileLayer(this._config.tiles_url, {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this._map);

    this._map_pin_icon = L.divIcon({
      className: 'map-icon',
      iconSize: [66, 92],
      iconAnchor: [33, 92],
      popupAnchor: [0, -50],
    });

    // L.icon({
    this._map_pin_interactive_icon = L.divIcon({
      className: 'map-icon interactive',
      iconSize: [66, 92],
      iconAnchor: [33, 92],
      popupAnchor: [0, -50],
    });


    this._add_stations();
  }

  _add_stations = () => {
    const stations = this._config.stations;
    this._markers = [];

    for (const station of stations) {
      const marker = L.marker(
        this._convert_station_location(station.location),
        {
          icon: station.url?this._map_pin_interactive_icon:this._map_pin_icon,
          alt: station.name,
          title: station.name,
          riseOnHover: true,
        }
      ).addTo(this._map);
      this._markers.push(marker);
      marker.bindPopup(station.name);
      marker.on('mouseover', function (e) {
        this.openPopup();
      });
      marker.on('mouseout', function (e) {
        this.closePopup();
      })
      marker.on('click', () => {
        if (station.url) {
          window.location = station.url
        }
      });
    }

    const pin_group = new L.featureGroup(this._markers);
    this._map.fitBounds(pin_group.getBounds(), {animate: false}).zoomOut(1);
  }


  disconnect($node) {
    super.disconnect($node);
    this._map = null;
    this._tile_layer = null;
    this.$node = null;
  }

  _convert_station_location = (location_string) => (location_string.split(',').map((a) => (parseFloat(a))))

// _add_stations = () => {
//   const locations = this._config.locations;
//   this._markers = [];
//
//   for (const location of locations) {
//     const marker = L.marker(
//       this._convert_station_location(location.location),
//       {
//         icon: this._map_pin_icon,
//         alt: location.name,
//         title: location.name,
//         riseOnHover: true,
//       }
//     ).addTo(this._map);
//     this._markers.push(marker);
//     marker.bindPopup(location.short_name);
//     marker.on('mouseover', function (e) {
//       this.openPopup();
//     });
//     marker.on('mouseout', function (e) {
//       this.closePopup();
//     })
//     marker.on('click', () => {
//       window.location = location.url
//     });
//   }
//   const pin_group = new L.featureGroup(this._markers);
//   this._map.fitBounds(pin_group.getBounds());
// }
}
