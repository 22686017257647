import gsap from "gsap";

import {plugin_registry, PluginBase} from "nk-plugin-registry"

@plugin_registry.register('PageHeader')
class PageHeader extends PluginBase {
  constructor($node) {
    super($node);
    this._$node = $node;
    this._$navigation_toggle = null;
    this._$overlay = null;

    this._overlay_opened = false;
    this._sticky = false;
  }

  get overlay_opened() {
    return this._overlay_opened;
  }

  set overlay_opened(new_state) {
    this._overlay_opened = new_state;
    if (new_state) {
      gsap.to(this._$overlay, {
        display: "block",
        opacity: 1,
        duration: 0.25,
      });
      this._$navigation_toggle.classList.add('is-active');
      document.body.classList.add('scroll-fix');
    } else {
      gsap.to(this._$overlay, {
        display: "none",
        opacity: 0,
        duration: 0.25,
      });
      this._$navigation_toggle.classList.remove('is-active');
      document.body.classList.remove('scroll-fix');
    }
  }

  toggle_overlay = (e) => {
    this.overlay_opened = !this.overlay_opened;
  }

  loaded($node){
    super.loaded($node);
    this._$navigation_toggle = $node.querySelector('[data-js-select="navigation-toggle"]');
    this._$overlay = $node.querySelector('[data-js-select="overlay"]');

    this._attach_events();
  }

  disconnect($node) {
    super.disconnect($node);
    this._detach_events();

    this._$node = undefined;
    this._$navigation_toggle = undefined;
    this._$overlay = undefined;
  }

  get sticky() {
    return this._sticky;
  }

  set sticky(new_sticky) {
    if (new_sticky !== this._sticky) {
      this._sticky = new_sticky;
      if (new_sticky) {
        this._$node.classList.add('sticky');
      } else {
        this._$node.classList.remove('sticky')
      }
    }
  }

  _make_sticky = (event) => {
    if (window.scrollY > 100) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  _attach_events = () => {
    this._$navigation_toggle.addEventListener('click', this.toggle_overlay);
    window.addEventListener('scroll', this._make_sticky);
  }

  _detach_events = () => {
    this._$navigation_toggle.removeEventListener('click', this.toggle_overlay);
    window.removeEventListener('scroll', this._make_sticky);
  }
}
